// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-news-blog-details-js": () => import("./../../../src/components/News/blog-details.js" /* webpackChunkName: "component---src-components-news-blog-details-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-residents-survey-js": () => import("./../../../src/pages/residents-survey.js" /* webpackChunkName: "component---src-pages-residents-survey-js" */),
  "component---src-template-page-index-js": () => import("./../../../src/template/page/index.js" /* webpackChunkName: "component---src-template-page-index-js" */)
}

